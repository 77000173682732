.info-header {
    margin-left: 15vw;
    width: 85vw;
    padding: 2rem 0;
    position: fixed;
    top: 0;
}

@media only screen and (max-width: 1439px) {
    .info-header {
        margin-left: 15vw;
        width: 85vw;
        padding: 1.2rem 0;
        position: fixed;
        top: 0;
    }
}
@media only screen and (max-width: 1023px) {}
@media only screen and (max-width: 767px) {
    .main-wrapper {
        padding: 0;
    }
    .info-header {
        margin: 0;
        width: 100vw;
        padding: 0.1rem;
    }
}