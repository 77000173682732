.category-wrapper {
  padding: 1rem;
  margin: 1rem;
  display: flex;
  gap: 1rem;
  .add-new-cat {
    width: 40%;
    background-color: white;
    padding: 1rem;
    form {
        .contentarea {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            .category, .slug, .description {
                display: flex;
                flex-direction: column;
                input {
                    padding: 0.3rem;
                }
            }
        }
        button {
          background-color: var(--bluegreen);
          color: white;
          padding: 0.5rem 0.7rem;
          border: none;
          border-radius: 0.2rem;
          float: right;
          margin-top: 1rem;
        }
    }
  }
  .cat-table {
    width: 60%;
    position: relative;
    background-color: white;
    padding: 1rem;
    .scroll {
      max-height: 75vh;
      overflow-y: auto;
      table {
        width: 100%;
        thead {
          position: sticky;
          top: 0;
          background-color: var(--bluegreen);
          color: var(--white);
          text-align: left;
          tr {
            th {
              padding: 0.2rem 0.5rem;
            }
          }
        }
        .tbody {
          tr {
            border-bottom: 1px solid black;
            td {
              padding: 0.2rem 0.5rem;
              img {
                height: 40px;
                width: 50px;
              }
              .action-icons {
                display: flex;
                gap: 0.5rem;
                a {
                  font-size: 1.5rem;
                }
                .trash {
                  font-size: 1.5rem;
                }
              }
            }
          }
        }
      }
    }
  }
  .pagination {
    display: flex;
    background-color: var(--berry);
    padding: 0.3rem;
    gap: 1rem;
    color: white;
    .news-size {
      display: flex;
    }
  }
}


@media only screen and (max-width: 1439px) {
  .category-wrapper {
    margin: 0;
    .add-new-cat {
      form {
          .contentarea {
              .category, .slug, .description {
                  input {
                  }
              }
          }
          button {
          }
      }
    }
    .cat-table {
      .scroll {
        table {
          thead {
            tr {
              th {
              }
            }
          }
          .tbody {
            tr {
              td {
                img {
                }
                .action-icons {
                  a {
                  }
                  .trash {
                  }
                }
              }
            }
          }
        }
      }
    }
    .pagination {
      .news-size {
      }
    }
  }
}
@media only screen and (max-width: 1023px) {}
@media only screen and (max-width: 767px) {}