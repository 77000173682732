.news-wrapper {
  margin: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  
  .contentarea {
    display: flex;
    flex-direction: column;

    .action-area {
      background-color: rgb(255, 255, 255);
      padding: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;

      .title-label {
        font-size: 1.7rem;
      }

      .btns {
        display: flex;
        gap: 1rem;

        button {
          color: white;
          padding: 0.6rem 1.2rem;
          font-size: 1rem;
          cursor: pointer;
          border: none;
          border-radius: 0.3rem;
        }

        .save-btn {
          background-color: rgb(108, 114, 112);
        }

        .save-btn:hover {
          background-color: rgb(201, 208, 206);
          color: var(--black);
        }

        .publish-btn {
          background-color: var(--bluegreen);
        }

        .publish-btn:hover {
          background-color: rgb(77, 114, 137);
          color: var(--white);
        }
      }
    }

    form {
      display: flex;
      gap: 1rem;
      // flex-grow: 1;

      .title-des {
        margin-top: 1rem;
        width: 80%;
        
        .title {
          display: flex;
          flex-direction: column;

          input {
            padding: 0.5rem;
            font-size: 1.2rem;
          }
        }

        .description {
          margin-top: 2rem;

          .inputarea {
            background-color: white;
            overflow-y: auto;

            .ql-editor {
              font-size: 1rem;
              height: 55vh;
            }
          }
        }
      }

      .cat-feature-sec {
        margin-top: 1rem;
        width: 20%;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        overflow-y: auto;
        max-height: calc(100vh - 20rem); /* Adjust based on the exact height needed */

        .feature-image {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: white;
          cursor: pointer;

          h3 {
            padding: 1rem;
          }

          img {
            width: 100%;
            height: 100%;
          }
        }

        .category {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          background-color: white;
          padding: 1rem;
          list-style-type: none;

          .cat-list {
            overflow-y: auto;
            height: 250px;
            display: flex;
            gap: 0.5rem;
            flex-wrap: wrap;

            li {
              width: 100%;

              label {
                font-weight: 500;
                display: flex;
                align-items: center;
                gap: 0.5rem;
                cursor: pointer;

                input {
                  height: 1.1rem;
                  width: 1.1rem;
                  cursor: pointer;
                }
              }
            }
          }

          .form {
            width: 100%;
            text-align: center;
            display: flex;
            gap: 1rem;

            input {
              padding: 0.4rem;
              width: 100%;
            }

            button {
              padding: 0.4rem;
            }
          }
        }

        .tags {
          background-color: white;
          padding: 1rem;
          list-style-type: none;
        }
      }
    }
  }
}


@media only screen and (max-width: 1439px) {
  .news-wrapper {
    margin: 0;
    .contentarea {
      .action-area {
        .title-label {
        }
        .btns {
          button {
          }
          .save-btn {
          }
          .save-btn:hover {
          }
          .publish-btn {
          }
          .publish-btn:hover {
          }
        }
      }
      form {
        .title-des {
          .title {
            input {
            }
          }
          .description {
            .inputarea {
  
              .ql-editor {
              }
            }
          }
        }
        .cat-feature-sec {
          .feature-image {
            h3 {
            }
            img {
            }
          }
          .category {
            .cat-list {
              li {
                width: 100%;
                label {
                  input {
                  }
                }
              }
            }
            .form {
              input {
              }
              button {
              }
            }
          }
          .tags {
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1023px) {}
@media only screen and (max-width: 767px) {
  .news-wrapper {
    margin: 0;
    .contentarea {
      height: 80vh;
      overflow-y: auto;
      .action-area {
        .title-label {
        }
        .btns {
          button {
          }
          .save-btn {
          }
          .save-btn:hover {
          }
          .publish-btn {
          }
          .publish-btn:hover {
          }
        }
      }
      form {
        flex-direction: column;
        .title-des {
          width: 100%;
          .title {
            input {
            }
          }
          .description {
            .inputarea {
  
              .ql-editor {
                height: auto;
              }
            }
          }
        }
        .cat-feature-sec {
          width: 100%;
          .feature-image {
            h3 {
            }
            img {
            }
          }
          .category {
            .cat-list {
              li {
                width: 45%;
                label {
                  input {
                  }
                }
              }
            }
            .form {
              input {
              }
              button {
              }
            }
          }
          .tags {
          }
        }
      }
    }
  }
}