.unable-wrapper {
    background-color: white;
    margin: 1rem 2rem;
    padding: 1rem;
    color: red;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
}

@media only screen and (max-width: 1439px) {}
@media only screen and (max-width: 1023px) {}
@media only screen and (max-width: 767px) {}