.sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 14vw;
    padding: 1rem;
    margin: 2rem;
    background-color: white;
    .logo {
        padding: 1rem;
        width: 100%;

        img {
            width: 100%;
        }
    }

    .site-view {
        background-color: var(--bluegreen);
        padding: 0.5rem;
        border-radius: 0.3rem;

        a {
            color: var(--white);
            padding: 0.5rem;

        }
    }

    .nav-list {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;

        .active {
            background-color: rgb(101, 5, 77);
            color: white;
        }

        li {
            .file-upload {
                padding: 0.5rem;
            }

            .uploading-overlay {
                padding: 0.5rem;

                .uploading-text {
                    color: red;
                }
            }

            a {
                text-decoration: none;
                color: black;
                font-weight: 500;
                display: flex;
                align-items: center;
                gap: 1rem;
                padding: 0.5rem 0.8rem;
            }
        }

        li a:hover {
            background-color: rgb(112, 4, 65);
            color: white;
        }

        .logout {
            background-color: var(--bluegreen);
            border-radius: 0.3rem;

            a {
                color: var(--white);
                font-size: 1rem;
            }
        }
    }
}

@media only screen and (max-width: 1439px) {
    .sidebar {
        margin: 0;
        padding: 0.5rem;

        .logo {
            padding: 0.5rem;

            img {}
        }

        .site-view {
            a {}
        }

        .nav-list {
            .active {}

            li {
                .uploading-overlay {
                    .uploading-text {}
                }

                a {}
            }

            li a:hover {}

            .logout {
                a {}
            }
        }
    }
}

@media only screen and (max-width: 1023px) {
    .sidebar {
        padding: 0;
        width: 16vw;

        .logo {
            img {}
        }

        .site-view {
            a {}
        }

        .nav-list {
            .active {}

            li {
                .uploading-overlay {
                    .uploading-text {}
                }

                a {}
            }

            li a:hover {}

            .logout {
                a {}
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .sidebar {
        position: fixed;
        padding: 1rem;
        top: 1rem;
        left: 0;
        width: 250px;
        height: 100%;
        background-color: #ebe9e9;
        padding: 20px;
        z-index: 1000;
        gap: 1rem;

        &.hidden {
            display: none;
        }

        &.visible {
            display: block;
        }

        .close-btn {
            background: none;
            border: none;
            color: rgb(139, 7, 111);
            font-size: 24px;
            cursor: pointer;
            position: absolute;
            top: 10px;
            right: 10px;
        }

        .logo {
            margin-bottom: 1rem;
            img {}
        }

        .site-view {
            margin-bottom: 0.5rem;
            text-align: center;
            a {}
        }

        .nav-list {
            .active {}

            li {
                color: black;
                .uploading-overlay {
                    .uploading-text {}
                }

                a {}
            }

            li a:hover {}

            .logout {
                text-align: center;
                bottom: 2rem;
                a {}
            }
        }
    }
}

@media (min-width: 768px) { // Adjust the width based on your requirements
    .close-btn {
      display: none; // Hide the close button on larger screens
    }
  }