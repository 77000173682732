.writer-wrapper {
  max-height: 75vh;
  overflow-y: auto;
  .writer-nav {
    background-color: white;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
  }
  .user-filter {
    background-color: var(--white);
    padding: 1rem;
    display: flex;
    gap: 1rem;
    input,
    select {
      padding: 0.3rem;
    }
    .action-buttons {
      display: flex;
      align-items: center;
      gap: 1rem;padding: 0 1rem;
      a {
        display: flex;
        align-items: center;
      }
    }
  }
  .user-table {
    position: relative;
    background-color: white;
    .scroll {
      table {
        width: 100%;
        padding: 1rem;
        thead {
          position: sticky;
          top: 0;
          background-color: #fff;
          text-align: left;
          tr {
            th {
              padding: 0.5rem;
              background-color: var(--bluegreen);
              color: var(--white);
            }
          }
        }
        .tbody {
          tr:nth-child(even) {
            background-color: #f2f2f2;
          }
          tr {
            border-bottom: 1px solid black;
            td {
              padding: 0.2rem 0.5rem;
              img {
                height: 40px;
                width: 40px;
                border-radius: 50%;
              }
              .action-icons {
                display: flex;
                gap: 0.5rem;
                a {
                  font-size: 1.5rem;
                }
                .trash {
                  font-size: 1.5rem;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
  .pagination {
    background-color: var(--berry);
    padding: 0.6rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    color: white;
    .news-size {
      display: flex;
    }
  }
}


@media only screen and (max-width: 1439px) {}
@media only screen and (max-width: 1023px) {}
@media only screen and (max-width: 767px) {
  .writer-wrapper {
    padding: 0;
    .writer-nav {
    }
    .user-filter {
      input,
      select {
      }
      .action-buttons {
        a {
        }
      }
    }
    .user-table {
      width: 100vw;
      overflow-x: auto;
      .scroll {
        table {
          thead {
            tr {
              th {
              }
            }
          }
          .tbody {
            tr:nth-child(even) {
            }
            tr {
              td {
                img {
                }
                .action-icons {
                  a {
                  }
                  .trash {
                  }
                }
              }
            }
          }
        }
      }
    }
    .pagination {
      .news-size {
      }
    }
  }
}