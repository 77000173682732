.item-count {width: 2%;}
.item-checkbox {width: 2%;}
.item-title {width: 25%;}
.item-author {width: 10%;}
.item-category {width: 25%;}
.item-date {width: 10%;}
.item-status {width: 10%;}
.item-action {width: 16%;}

.news-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 75vh;
  overflow-y: auto;
  .news-top {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    background-color: var(--white);
    a {
      background-color: var(--berry);
      color: var(--white);
      padding: 0.3rem 0.7rem;
      border-radius: 0.2rem;
    }
    p {
      font-size: 1.2rem;
    }
  }
  .news-filter {
    background-color: var(--white);
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    #selectStatus {
    }
    input,
    select {
      padding: 0.3rem;
    }
    .action-buttons {
      display: flex;
      align-items: center;
      gap: 1rem;padding: 0 1rem;
      a {
        display: flex;
        align-items: center;
      }
    }
  }
  .news-table {
    position: relative;
    background-color: white;
    .scroll {
      table {
        width: 100%;
        padding: 1rem;
        thead {
          position: sticky;
          top: 0;
          background-color: #fff;
          text-align: left;
          tr {
            th {
              padding: 0.5rem;
              background-color: var(--bluegreen);
              color: var(--white);
            }
          }
        }
        .tbody {
          tr:nth-child(even) {
            background-color: #f2f2f2;
          }
          tr {
            border-bottom: 1px solid black;
            td {
              padding: 0.5rem;
              .action-icons {
                display: flex;
                gap: 0.5rem;
                a {
                  font-size: 1.5rem;
                }
                .trash {
                  font-size: 1.5rem;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
  .pagination {
    background-color: var(--berry);
    padding: 0.6rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    color: white;
    .news-size {
      display: flex;
    }
  }
}

@media only screen and (max-width: 1439px) {}
@media only screen and (max-width: 1023px) {}
@media only screen and (max-width: 767px) {
  .item-count, .item-author, .item-category, .item-action, .item-status, .item-date {display: none;}
  .news-content-wrapper {
    max-height: 90vh;
    .news-top {
      padding: 0;
      a {
      }
      p {
      }
    }
    .news-filter {
      flex-wrap: wrap;
      justify-content: space-between;
      #selectStatus {
      }
      input,
      select {
      }
      .action-buttons {
        a {
        }
      }
    }
    .news-table {
      width: 100%;
      overflow-x: auto;
      margin: 0;
      padding: 0;
      .scroll {
        table {
          padding: 0;
          thead {
            tr {
              th {
              }
            }
          }
          .tbody {
            tr:nth-child(even) {
            }
            tr {
              td {
                .action-icons {
                  a {
                  }
                  .trash {
                  }
                }
              }
            }
          }
        }
      }
    }
    .pagination {
      .news-size {
      }
    }
  }
}
