.login-wrapper {
    width: 30%;
    margin: auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    .logo {
        background-color: white;
        padding: 3rem 1rem;
        width: 100%;
        text-align: center;
        img {
            width: 40%;
        }
    }
    form {
        background-color: white;
        width: 100%;
        padding: 4rem 2rem;
        flex-direction: column;
        gap: 1rem;
        .inputarea {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            .username, .password {
                display: flex;
                flex-direction: column;
                padding: 0.5rem;
                input {
                    padding: 0.3rem;
                }
            }
        }
        button {
            padding: 0.3rem 1rem;
            margin-right: 0.5rem;
            background-color: var(--bluegreen);
            color: var(--white);
            float: right;
            border: none;
            border-radius: 0.2rem;
        }
    }
}


@media only screen and (max-width: 1439px) {}
@media only screen and (max-width: 1023px) {}
@media only screen and (max-width: 767px) {
    .login-wrapper {
        width: 100%;
        .logo {
            img {
            }
        }
        form {
            .inputarea {
                .username, .password {
                    input {
                    }
                }
            }
            button {
            }
        }
    }
}