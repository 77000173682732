.newsarea {
  padding: 1rem;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
    height: 80vh;
  .noticearea {
    p {
      color: rgb(197, 14, 8);
      padding: 1rem;
    }
  }
  .welcome-area {
    background-color: black;
    padding: 4rem;
    position: relative;
    h1 {
      text-align: center;
      a {
        color: white;
        font-size: 2rem;
      }
    }
    .close-btn {
      color: white;
      position: absolute;
      right: 1.5rem;
      top: 1.5rem;
      font-size: 2rem;
      cursor: pointer;
    }
  }
  .counter {
    background-color: white;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
    .count {
      text-align: center;
      padding: 1rem;
      background-color: azure;
    }
  }
  .recent-news {
    background-color: white;
    .all-news {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
    }
    .news-table {
      position: relative;
      .scroll {
        table {
          width: 100%;
          thead {
            position: sticky;
            top: 0;
            background-color: #fff;
            text-align: left;
            tr {
              th {
                padding: 0.2rem 0.5rem;
              }
            }
          }
          .tbody {
            tr {
              border-bottom: 1px solid black;
              td {
                padding: 0.2rem 0.5rem;
                img {
                  height: 40px;
                  width: 50px;
                }
                .action-icons {
                  display: flex;
                  gap: 0.5rem;
                  a {
                    font-size: 1.5rem;
                  }
                  .trash {
                    font-size: 1.5rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 1439px) {}
@media only screen and (max-width: 1023px) {}
@media only screen and (max-width: 767px) {
  .newsarea {
    padding: 0;
    .noticearea {
      padding: 0;
      p {
      }
    }
    .welcome-area {
      h1 {
        a {
        }
      }
      .close-btn {
      }
    }
    .counter {
      .count {
      }
    }
  }
}