.gallery-wrapper {
  .gallery-list {
    position: absolute;
    width: 95%;
    background-color: rgb(255, 255, 255);
    border: 1px solid red;
    top: 10%;
    z-index: 100;
    padding: 1rem;
    .toggle {
      padding: 1rem;
      .upload {
        label {
          .upload-btn {
            span {
              cursor: pointer;
              background-color: rgb(170, 0, 0);
              color: white;
              padding: 0.5rem 0.8rem;
              border-radius: 0.5rem;
            }
          }
        }
      }
      .close-btn {
        cursor: pointer;
        position: absolute;
        right: 2rem;
        top: 2rem;
        font-size: 1.5rem;
        background-color: rgb(170, 0, 0);
        color: white;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .close-btn:hover {
        background-color: rgb(187, 12, 29);
        color: white;
      }
    }
    .upload {
      label {
      }
      input {
        visibility: hidden;
      }
    }
    .image-gallery {
      max-height: 60vh;
      overflow-y: auto;
      display: flex;
      flex-wrap: wrap;
      .image-item {
        position: relative;
        width: 10rem;
        aspect-ratio: 3 / 2; /* Equivalent to 4:6 Aspect Ratio */
        overflow: hidden;
        cursor: pointer;
        padding: 0.1rem;
        border: 0.1rem solid transparent;
        transition: border-color 0.3s;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        &.selected {
          border-color: #007bff; /* Change to your preferred color */
        }
        .dropdown {
          position: absolute;
          top: 5px;
          right: 5px;

          .dropdown-btn {
            background: rgba(0, 0, 0, 0.5);
            padding: 0.3rem;
            border: none;
            border-radius: 0.2rem;
            cursor: pointer;
            font-size: 20px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .dropdown-content {
            position: absolute;
            right: 0;
            background-color: #fff;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            z-index: 1;

            .delete-btn {
              background-color: red;
              color: white;
              border: none;
              padding: 10px;
              text-align: left;
              width: 100%;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: flex-start;

              svg {
                margin-right: 5px;
              }

              &:hover {
                background-color: darkred;
              }
            }
          }

          &.visible .dropdown-content {
            display: block;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1439px) {
}
@media only screen and (max-width: 1023px) {
}
@media only screen and (max-width: 767px) {
}
