.head-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 6rem;
    padding: 1rem;
    margin: 0 2rem;
    background-color: var(--berry);
    color: var(--white);
    .mobile-sidebar {
        display: none;
    }
    .search {
        input {
            padding: 0.3rem;
        }
    }
    .sidebar-tog {
        display: none;
    }
    .sm-profile {
        height: 100%;
        display: flex;
        align-items: center;
        gap: 1rem;
        .info {
            text-align: center;
        }
        img {
            height: 100%;
            border-radius: 50%;
            background-color: var(--white);
        }
    }
}

@media only screen and (max-width: 1439px) {
    .head-wrapper {
        margin: 0 1rem;
        .search {
            input {
            }
        }
        .sm-profile {
            .info {
            }
            img {
            }
        }
    }
}
@media only screen and (max-width: 1023px) {}
@media only screen and (max-width: 767px) {
    .head-wrapper {
        margin: 0;
        .mobile-sidebar {
            .mobile-side {
                // display: block;
            }
            display: flex;
            font-size: 1.5rem;
        }
        .search {
            display: none;
            input {
            }
        }
        .sm-profile {
            .info {
            }
            img {
            }
        }
    }
}