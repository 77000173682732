.writer-wrapper {
  margin: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .writer-head {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    background-color: white;
  }
  .form-sec {
    background-color: white;
    form {
      .input-row {
        display: flex;
        gap: 2rem;
        padding: 1rem;
        .input-sec {
          width: 50%;
          display: flex;
          flex-direction: column;
          .required {
            color: red;
          }
          input,
          select {
            padding: 0.3rem;
          }
        }
        .input-sec input.error,
        .input-sec select.error {
          border: 1px solid red;
        }
      }
      .avatar-cover {
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        gap: 2rem;
        .avatar,
        .coverImage {
          display: flex;
          align-items: center;
          height: 8rem;
          gap: 1rem;
          h4 {
            padding: 0.5rem;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            text-align: center;
            padding: 2rem;
            width: 8rem;
            cursor: pointer;
          }
          img {
            height: 100%;
            object-fit: cover;
            padding: 0.5rem;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            cursor: pointer;
          }
        }
        .avatar {
          width: 50%;
          img {
            width: 8rem;
          }
        }
        .coverImage {
          width: 50%;
          img {
            width: 12rem;
          }
        }
      }
      .btn-sec {
        padding: 1rem;
        button {
          color: white;
          background-color: rgb(1, 109, 106);
          padding: 0.5rem;
          border-radius: 0.2rem;
          border: none;
          cursor: pointer;
        }
      }
    }
  }
}


@media only screen and (max-width: 1439px) {}
@media only screen and (max-width: 1023px) {}
@media only screen and (max-width: 767px) {
  .writer-wrapper {
    margin: 0;
    .writer-head {
    }
    .form-sec {
      form {
        .input-row {
          flex-direction: column;
          gap: 1.5rem;
          width: 100%;
          align-items: center;
          .input-sec {
            width: 90%;
            display: flex;
            flex-direction: column;
            .required {
            }
            input,
            select {
              padding: 0.3rem;
            }
          }
          .input-sec input.error,
          .input-sec select.error {
            border: 1px solid red;
          }
        }
        .avatar-cover {
          flex-direction: column;
          padding: 2.5rem;
          .avatar,
          .coverImage {
            h4 {
              cursor: pointer;
            }
            img {
            }
          }
          .avatar {
            img {
            }
          }
          .coverImage {
            img {
            }
          }
        }
        .btn-sec {
          button {
          }
        }
      }
    }
  }
}