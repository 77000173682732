.profile-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    background-color: white;
    margin: 1rem 2rem;
    padding: 1rem;
  .profile {
    width: 50%;
    display: flex;
    gap: 1rem;
    .profile-img {
      img {
        width: 8rem;
        height: 8rem;
      }
    }
    .info {
    }
  }
  .pw-change {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .pw-input {
        p {
        }
        .input-sec {
            display: flex;
            flex-direction: column;
            gap: 1rem;
          .old-pw,
          .new-pw {
            display: flex;
            flex-direction: column;
            label {
            }
            input {
            }
          }
        }
    }
  }
}


@media only screen and (max-width: 1439px) {}
@media only screen and (max-width: 1023px) {}
@media only screen and (max-width: 767px) {}