@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: var(--black);
}
body {
  background-color: #dad5d5;
}

h1 {
  color: var(--dark);
  font-family: "Mukta", sans-serif;
  font-weight: 400;
}

.titleHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.3rem 1.5rem;
    background: linear-gradient(
      358deg,
      rgba(255, 0, 0, 1) 22%,
      rgb(30, 70, 148) 54%,
      rgb(21, 4, 119) 95%
    );
    color: white;
  }

  :root {
    --orange: #ff0000;
    --green: #013d20;
    --bluegreen: #055e81;
    --red: #720505;
    --white: #ffffff;
    --black: #1a1918;
    --dark: #000000;
    --grey: #737271;
    --berry: #7c0c48;
    --lightGrey: #e6e6e6;
    --title-color: #00146b;
    --text-color: #00146b;
  }

  @media only screen and (max-width: 1439px) {}
@media only screen and (max-width: 1023px) {}
@media only screen and (max-width: 767px) {}